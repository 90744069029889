// Add background image to homepage
.homepage {
    background-image: url($assets-path + 'squiggle-blue.svg');
    background-position: center 700px;
    background-size: 200%;
    background-repeat: repeat;
    background-color: $brand-secondary;
}
// Add a little space above CTA button row in header
.pageHeader .headerContent>*:not(.mainLogo) {
    margin-top: $spacer * 1.5;
}
// Align stats at the top
.homeImpactWrapper {
    tbody {
        align-items: flex-start;
    }
}
// Adjust spacing on feed 2
.homeFeedBox2 {
    padding-top: 0;
}
// Add border radius to card details
.homeFeatureDetailsWrapper,
.feedItemDetailsWrapper {
    border-radius: 3px;
}
// Remove underline in footer links
.pageFooter a:not(.button) {
    text-decoration: none;
}
// Fix spacing on social icons
.footerBox .socialIcons {
    margin-left: -$spacer / 2;
    margin-bottom: $spacer;
}
// Maintains aspect ratio 16:9 for video on mobile
.postVideoObjectEmbed {
    width: 100%;
    padding-top: 56.25%;
    height: 0px;
    position: relative;
    margin: $spacer * 2 0;
    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
// Add line to top of nav
.menuMain {
    border-top: 1px solid rgba(black, 0.2);
}
// Adjust colours for burger bar/button
.menuMainAlt {
    @media (max-width: map-get($breakpoints, lg)) {
        background-color: white;
    }
    @media (max-width: map-get($breakpoints, md)) {
        border-top: 1px solid rgba(black, 0.2);
        background-color: $brand-secondary;
    }
}
// Footer stuff
.footerBox.footerBox3 {
    grid-column: 10 / span 5;
    column-count: 2;
    @media (max-width: map-get($breakpoints, sm)) {
        grid-column: 3 / span 12;
        column-count: 1;
    }
}
.footerBox.footerBox4 {
    grid-column: 1 / span 16;
    background-color: white;
    margin: $spacer * 2 0 0 0;
    padding-bottom: $spacer * 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: $spacer calc((100vw - 1240px) / 2);
    img {
        max-height: 80px;
        width: auto;
        margin: $spacer;
    }
    @media (max-width: map-get($breakpoints, sm)) {
        display: block;
        img {
            display: block;
            margin: $spacer auto;
        }
    }
}
// Remove bottom padding
.pageFooter {
    padding-bottom: 0;
}
// // Add line illustration to top of home intro
// .homeIntro {
//     padding-top: 120px;
//     &:after {
//         content:"";
//         background-image: url($assets-path + 'amp_mic.svg');
//         background-repeat: no-repeat;
//         background-position: center;
//         background-size: contain;
//         position: absolute;
//         width: 100%;
//         height: 108px;
//         top: 0;
//         left: 0;

//     }
// }
// Modify breakpoints for sub sub menu flyout to right
@media (min-width: 1025px) {
    ul.topLevel>li:nth-last-of-type(4) ul.subMenu:not(.level2) 
    {
        left: 100% !important;
    }
}
@media (min-width: 1400px) {
    ul.topLevel>li:nth-last-of-type(3) ul.subMenu:not(.level2)
    {
        left: 100% !important;
    }
}

// QA fixes
.carousel.hasCaption .carouselSlideTitle.showsVideo::before {
    right: 50%;
}

// Page no sidebar (Catgeory)
body.PostCategory_page-no-sidebar {
    .post .contentBlock {
        // display: block;
        .postAside { display: none; }
    }
}

// 1254131 SA - Mailchimp newsletter
.footerBox[class*='ewsletter'] {
  #mc_embed_signup {
    width: 100%;
    max-width: $container-max-width;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  #mc_embed_signup_scroll {
    position: relative;
    display: flex;
    flex-wrap: wrap;

    > .mc-field-group {
      display: flex;
      flex-direction: column;
      margin: 0 0 $spacer 0;

      > label {
        margin: 0;
        color: rgba($white, 0.65);
        font-size: $font-size-small;
      }

      @media (max-width: map-get($breakpoints, sm)) {
        width: 100%;
        margin: 0 0 10px 0;
      }
    }

    input#mc-embedded-subscribe {
      margin-top: 10px;
      margin-left: 10px;
      background-color: $brand-secondary;
      color: text-contrast($brand-secondary) !important;

      &:hover {
        background-color: darken($brand-secondary, 10%);
      }
    }
  }
  .input-section {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;
    .mc-field-group {
      flex-grow: 1;
      margin: 0 10px;
      @media (max-width: 768px) {
        width: 100%;
      }
      input {
        width: 100%;
      }
    }
  }
}